// prettier-ignore
const MIDI_KEYSIGS = [
  ["Cb", "Gb", "Db", "Ab", "Eb", "Bb", "F", "C", "G", "D", "A", "E", "B", "F#", "C#"],
  ["Abm", "Ebm", "Bbm", "Fm", "Cm", "Gm", "Dm", "Am", "Em", "Bm", "F#m", "C#m", "G#m", "D#m", "A#m"],
];

interface MidiInfo {
  key: string;
  timesig: string;
  bpm: number;
  [key: string]: string | number;
}

export default function extractMidiInfo(fileData: Uint8Array): MidiInfo | null {
  const view = new DataView(fileData.buffer, fileData.byteOffset, fileData.byteLength);
  // check for MThd header
  if (view.getUint32(0) !== 0x4d546864) {
    return null;
  }
  const len = view.getUint32(4);
  if (view.getUint32(len + 8) != 0x4d54726b) {
    return null;
  }
  const trkstart = len + 16;
  const trkend = Math.min(view.getUint32(len + 12) + trkstart, fileData.byteLength);

  let bpm = 0;
  let timesig = "X#X";
  let key = "C";

  let looking = 0x7;

  for (let offset = trkstart; looking && offset < trkend + 5; ) {
    if (fileData[offset] !== 0xff) {
      offset++;
      continue;
    }

    const ev = view.getUint16(offset + 1);
    if (ev === 0x5103 && offset + 6 <= trkend) {
      const us_per_beat = (view.getUint16(offset + 3) << 8) | view.getUint8(offset + 5);
      bpm = Math.round(60000000 / us_per_beat);

      looking &= ~0x1;
    } else if (ev === 0x5902) {
      const keybytes = view.getUint16(offset + 3);
      key = MIDI_KEYSIGS[keybytes & 0x1][((keybytes >> 8) ^ 0x80) - 0x80 + 7];

      looking &= ~0x2;
    } else if (ev === 0x5804 && offset + 7 <= trkend) {
      timesig = `${view.getUint8(offset + 3)}#${1 << view.getUint8(offset + 4)}`;

      looking &= ~0x4;
    }
    offset += (ev & 0xff) + 4; // 4 = 3 (FF [event type] [len]) + 1 (minimum delta time)
  }

  return { key, timesig, bpm };
}
