export default class TemplateProcessor {
  private parts: (string | number)[] = [];
  private vars = new Map<number, string>();

  constructor(readonly template: string) {
    let varN = 0;
    const vars = new Map<string, number>();

    let i = 0;
    const parts = this.parts;
    while (i < template.length) {
      const bi = template.indexOf("{", i);
      if (bi === -1) {
        parts.push(template.substring(i));
        break;
      } else if (bi + 1 < template.length && template[bi + 1] === "{") {
        parts.push(template.substring(i, bi + 1));
        i = bi + 2;
      } else {
        const part = template.substring(i, bi);
        if (part) parts.push(part);

        const ei = template.indexOf("}", bi + 1);
        if (ei !== -1) {
          // extract variable name
          const varName = template.substring(bi + 1, ei);
          let v = vars.get(varName);
          if (v === undefined) {
            v = varN++;
            vars.set(varName, v);
          }
          parts.push(v);
          i = ei + 1;
        } else {
          // Missing closing brace, treat '{' as literal and push rest of string
          parts.push(template.substring(bi));
          break;
        }
      }
    }
    for (const [k, v] of vars) {
      this.vars.set(v, k);
    }
  }

  process(
    map?: Record<string, string | number | undefined>,
    sub?: (v: string) => string | undefined,
    err = "#ERR#",
  ): string {
    const vars = this.vars;
    const out = this.parts.slice();
    for (let i = 0; i < out.length; i++) {
      const p = out[i];
      if (typeof p === "number") {
        const varName = vars.get(p)!;
        out[i] = map?.[varName] ?? sub?.(varName) ?? err;
      }
    }
    return out.join("");
  }
}
