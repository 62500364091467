// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyClIMhw-YlTGlNQlql8Nxjn_PTrot5ExZo",
  authDomain: "ttfstool.firebaseapp.com",
  projectId: "ttfstool",
  storageBucket: "ttfstool.firebasestorage.app",
  messagingSenderId: "1087347715651",
  appId: "1:1087347715651:web:3d49cf5bfc44954b73aef6",
  measurementId: "G-2KM8TGXLT5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
