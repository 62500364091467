interface Tree {
  readDir(entry?: DirEntry): IterableIterator<Entry>;
  get root(): DirEntry;
}

interface DirEntry {
  name: string;
  offset: number;
}

interface FileEntry extends DirEntry {
  size: number;
}

type Entry = DirEntry | FileEntry;

class Item {
  _skip = false;
  constructor(
    readonly entry: Entry,
    readonly path: string[],
  ) {}

  skip() {
    this._skip = true;
  }

  get pathname(): string {
    return [...this.path, this.entry.name].join("/");
  }
}

export function* iterate(tree: Tree, top?: DirEntry, path?: string[]): IterableIterator<Item> {
  top = top || tree.root;
  path = path || [];
  const item = new Item(top, path);
  yield item;
  if (item._skip) {
    return;
  }
  if (top !== tree.root) {
    path.push(top.name);
  }
  for (const entry of tree.readDir(top)) {
    if ("size" in entry) {
      yield new Item(entry, path);
    } else {
      yield* iterate(tree, entry, path);
    }
  }
  path.pop();
}

export class TtfsTree implements Tree {
  #root: DirEntry;
  private readonly view: DataView;
  private readonly decoder: TextDecoder = new TextDecoder("ASCII");

  constructor(private readonly data: Uint8Array) {
    const view = (this.view = new DataView(data.buffer, data.byteOffset, data.byteLength));
    if (view.getUint32(0) !== 0x74746673) {
      throw new Error("Not a TTFS file");
    }
    const rootOffset = view.getUint32(6, true) + 16;
    this.#root = { name: "", offset: rootOffset };
  }

  *readDir(entry?: DirEntry): IterableIterator<Entry> {
    const view = this.view;
    const data = this.data;

    let ptr = (entry && entry.offset) || this.#root.offset;
    const count = view.getUint32(ptr, true);
    ptr += 4;
    for (let i = 0; i < count; i++) {
      const offset = view.getUint32(ptr, true);
      const size = view.getUint32(ptr + 4, true);

      const s = ptr + 8;
      let e = s;
      for (; this.data[e] !== 0; e++) {
        // find terminating NUL
      }
      const name = this.decoder.decode(data.subarray(s, e));

      ptr = e + 1;

      if (offset == 0xffffffff || size > 0) {
        const empty = offset == 0xffffffff;
        yield {
          name,
          offset: empty ? 0 : offset,
          size: empty ? 0 : size,
        };
      } else {
        yield { name, offset };
      }
    }
  }

  get root(): DirEntry {
    return this.#root;
  }

  readFile(entry: FileEntry): Uint8Array {
    return this.data.subarray(entry.offset, entry.offset + entry.size);
  }
}
