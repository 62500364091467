import { iterate, TtfsTree } from "./ttfs.ts";
import { saveAs } from "file-saver";
import TemplateProcessor from "./tmpl.ts";
import extractMidiInfo from "./midi.ts";
import * as fflate from "fflate";

const supportsDirectoryWrite = "showDirectoryPicker" in window;

if (supportsDirectoryWrite) {
  document.querySelectorAll(".fs-api").forEach((el) => {
    el.classList.toggle("fs-api-hide");
  });
}

function sel<T extends HTMLElement>(s: string, p: HTMLElement | Document = document): T {
  const el = p.querySelector(s);
  if (!el) {
    throw new Error(`Element '${sel}' not found`);
  }
  return el as T;
}

function selInput(s: string, p: HTMLElement | Document = document): HTMLInputElement {
  return sel<HTMLInputElement>(s, p);
}

function byId<T extends HTMLElement>(id: string): T {
  const el = document.getElementById(id);
  if (!el) {
    throw new Error(`Element '#${id}' not found`);
  }
  return el as T;
}

function handleEvent<T extends HTMLElement, K extends keyof HTMLElementEventMap>(
  e: T,
  type: K,
  cb: (ev: HTMLElementEventMap[K] & { target: T }) => void,
  options?: boolean | AddEventListenerOptions,
): typeof cb {
  e.addEventListener(type, cb as EventListener, options);
  return cb;
}

interface Options {
  renamer?: TemplateProcessor;
  includeEmpty?: boolean;
  includeSidecar?: boolean;
}

function dirName(path: string) {
  return path.substring(0, path.lastIndexOf("/"));
}

function splitExt(path: string) {
  const e = path.lastIndexOf(".");
  return e === -1 ? [path, ""] : [path.substring(0, e), path.substring(e + 1)];
}

interface Writer {
  mkfile(path: string, data: Uint8Array, mtime?: number): void;
  mkdir(path: string): void;
}

class ZipWriter implements Writer {
  constructor(
    readonly files: fflate.AsyncZippable = {},
    readonly mtime?: Date | number,
  ) {}

  mkfile(path: string, data: Uint8Array, mtime?: number) {
    this.files[path] = [data, { mtime }];
  }

  mkdir(path: string) {
    this.files[path] = {};
  }
}

class DirectoryWriter implements Writer {
  files = new Map<string, Uint8Array | null>();

  constructor(readonly root: FileSystemDirectoryHandle) {}

  mkfile(path: string, data: Uint8Array) {
    if (!path) {
      throw new Error("File name empty");
    }
    this.files.set(path, data);
  }

  mkdir(path: string) {
    if (!path) {
      throw new Error("Path name empty");
    }
    this.files.set(path, null);
  }

  flush(progressCb?: (progress: number) => void): Promise<void>[] {
    const root = this.root;

    const dentryCache = new Map<string, Promise<FileSystemDirectoryHandle>>();
    dentryCache.set("", Promise.resolve(root));

    const total = this.files.size;
    let i = 0;

    async function resolveDir(path: string) {
      const parts = path.split("/");
      let current = root;
      for (const part of parts) {
        current = await current.getDirectoryHandle(part, { create: true });
      }
      return current;
    }

    function getDirFromPath(path: string) {
      let dir = dentryCache.get(path);
      if (dir) {
        return dir;
      }
      dir = resolveDir(path);
      dentryCache.set(path, dir);
      return dir;
    }

    async function handleFile(path: string, data: Uint8Array | null) {
      if (!data) {
        await getDirFromPath(path);
        return;
      }
      const ei = path.lastIndexOf("/");
      const dirPath = path.substring(0, ei);
      const fileName = path.substring(ei + 1);

      const dir = dirPath ? await getDirFromPath(dirPath) : root;
      const fh = await dir.getFileHandle(fileName, { create: true });
      const writable = await fh.createWritable();
      i += 0.5;
      progressCb?.(i / total);
      await writable.write(data);
      await writable.close();
      i += 0.5;
      progressCb?.(i / total);
    }

    const work = [];
    for (const [p, data] of this.files) {
      const path = p.replace(/\/\/+/g, "/");
      work.push(handleFile(path, data));
    }

    this.files.clear();

    return work;
  }
}

const DELIMITERS = ["_", "-", " ", "/", "+", "@"];

function addTree(writer: Writer, ttfs: TtfsTree, packName: string, mtime?: number, options?: Options) {
  const renamer = options?.renamer;

  // remove any extension
  packName = packName.substring(0, packName.lastIndexOf(".")) || packName;
  // lower case the part after the last @
  const normPackName = packName.substring(packName.lastIndexOf("@") + 1).toLowerCase();

  const dirCounters = new Map<string, number>();
  let counter = 0;
  let lastDir: string | undefined;

  let lastMidiOrigPathWithoutExt: string | undefined;
  let lastMidiOutputPathWithoutExt: string | undefined;

  for (const item of iterate(ttfs)) {
    const entry = item.entry;
    if ("size" in entry) {
      if (!entry.size && !options?.includeEmpty) {
        continue;
      }

      const origPath = item.pathname;
      const [origPathWithoutExt, ext] = splitExt(origPath);

      // filters
      if (ext.startsWith("midinfo") && !options?.includeSidecar) {
        continue;
      } else if (ext === "dlx") {
        // at least one pack has a partially recursive file in it. Skip it.
        continue;
      }

      let outputPath;

      if (!renamer) {
        outputPath = `${packName}/${origPath}`;
      } else {
        let outputPathWithoutExt = origPathWithoutExt;

        if (ext === "mid") {
          const midiInfo = extractMidiInfo(ttfs.readFile(entry));

          let rhythm = midiInfo?.timesig ?? "X#X";

          if (item.path.length >= 2) {
            const p = item.path[item.path.length - 2];
            const i = p.indexOf("@");
            if (i !== -1) {
              const rn = p.substring(i + 1);
              if (rn.startsWith("Swing")) {
                rhythm += "s";
              }
            }
          }

          const vars: Record<string, string | number | undefined> = {
            filename: packName,
            pack: normPackName.toLowerCase(),
            bpm: midiInfo?.bpm ?? 0,
            rhythm,
            timesig: midiInfo?.timesig ?? "X#X",
            key: midiInfo?.key ?? "C",
            i: ++counter,
            path: dirName(origPathWithoutExt),
            fullpath: origPath,
          };

          function sub(v: string) {
            if (!v) return;
            let name = v;

            const hi = v.indexOf("#");
            if (hi !== -1) {
              name = v.substring(0, hi);
            }

            const prefixDelimIndex = DELIMITERS.indexOf(name[0]);
            const suffixDelimIndex = DELIMITERS.indexOf(name[name.length - 1]);
            let prefixDelim = "";
            let suffixDelim = "";
            if (prefixDelimIndex !== -1) {
              name = name.substring(1);
              prefixDelim = DELIMITERS[prefixDelimIndex];
            }
            if (suffixDelimIndex !== -1) {
              name = name.substring(0, name.length - 1);
              suffixDelim = DELIMITERS[suffixDelimIndex];
            }

            let value = vars[name];
            if (value === undefined) {
              switch (name) {
                case "part": {
                  const p = item.path[item.path.length - 1] ?? "";
                  const i = p.indexOf("@");
                  if (i !== -1) {
                    value = p.substring(i + 1);
                  }
                  break;
                }
                case "var": {
                  const match = entry.name.match(/^\w+_(\d+)\.mid$/);
                  if (match) {
                    value = parseInt(match[1]);
                  }
                  break;
                }
              }
            }
            if (value !== undefined && (prefixDelim || suffixDelim)) {
              value = prefixDelim + value.toString() + suffixDelim;
            }

            const w = v.substring(hi + 1);
            const totalLen = w ? parseInt(w) : 2;

            if (value !== undefined) {
              return value.toString().padStart(totalLen, "0");
            }
          }

          outputPathWithoutExt = renamer.process(vars, sub);
          const curDir = dirName(outputPathWithoutExt);

          if (lastDir && lastDir !== curDir) {
            dirCounters.set(lastDir, counter - 1);
            counter = (dirCounters.get(curDir) ?? 0) + 1;
            vars.i = counter;
            outputPathWithoutExt = renamer.process(vars, sub);
          }
          lastDir = curDir;
          lastMidiOrigPathWithoutExt = origPathWithoutExt;
          lastMidiOutputPathWithoutExt = outputPathWithoutExt;
        } else if (ext.startsWith("midinfo")) {
          if (lastMidiOrigPathWithoutExt && lastMidiOrigPathWithoutExt === origPathWithoutExt) {
            outputPathWithoutExt = lastMidiOutputPathWithoutExt!;
          }

          // terrible hack
        } else if (renamer.template.startsWith("{pack}/")) {
          outputPathWithoutExt = `${normPackName}/${outputPathWithoutExt}`;
        }

        outputPath = ext ? `${outputPathWithoutExt}.${ext}` : outputPathWithoutExt;
      }
      if (!outputPath) {
        continue;
      }

      writer.mkfile(outputPath, ttfs.readFile(entry), mtime);
    }
  }
}

function fileSystemEntryFile(entry: FileSystemFileEntry): Promise<File> {
  return new Promise((resolve, reject) => {
    entry.file(resolve, reject);
  });
}

function preventDefault(e: Event) {
  e.preventDefault();
}

document.addEventListener("dragover", preventDefault);
document.addEventListener("dragenter", preventDefault);
document.addEventListener("dragleave", preventDefault);
document.addEventListener("drop", preventDefault);

let updateControls = () => {
  // do nothing, redefined below
};

const errorDialog = byId<HTMLDialogElement>("error-dialog");
errorDialog.querySelector<HTMLButtonElement>("button")?.addEventListener("click", () => {
  errorDialog.close();
  updateControls();
});

window.addEventListener("unhandledrejection", (e) => {
  const messageEl = errorDialog.querySelector<HTMLParagraphElement>(".error-message")!;
  messageEl.textContent = `${e.reason}.`;
  messageEl.nextElementSibling!.textContent = "This may be caused by a funky template.";
  errorDialog.showModal();
});

window.addEventListener("error", (e) => {
  const { message, filename, lineno, colno } = e;
  const messageEl = errorDialog.querySelector<HTMLParagraphElement>(".error-message")!;
  messageEl.textContent = message.toString();
  const origin = window.location.origin;
  const filenameShort = filename.startsWith(origin) ? filename.slice(origin.length) : filename;
  messageEl.nextElementSibling!.textContent = `Location: ${filenameShort}:${lineno}:${colno}`;
  errorDialog.showModal();
});

{
  const form = byId<HTMLFormElement>("settings");
  const fileInput = byId<HTMLInputElement>("file");

  handleEvent(byId<HTMLInputElement>("sel-dir"), "click", (e) => {
    fileInput.webkitdirectory = e.target.checked;
  });

  const supportsFileSystemAPI = "getAsFileSystemHandle" in DataTransferItem.prototype;

  document.addEventListener("drop", async (e) => {
    e.preventDefault();
    form.style.outline = "";

    const fileHandlePromises = [...e.dataTransfer!.items]
      .filter((item) => item.kind === "file")
      .map((item) => (supportsFileSystemAPI ? item.getAsFileSystemHandle() : item.webkitGetAsEntry()));

    function isDirectory(
      handle: FileSystemHandle | FileSystemEntry,
    ): handle is FileSystemDirectoryHandle | FileSystemDirectoryEntry {
      return ("isDirectory" in handle && handle.isDirectory) || ("kind" in handle && handle.kind === "directory");
    }

    const dataTransfer = new DataTransfer();

    async function readDirectory(dir: FileSystemDirectoryEntry | FileSystemDirectoryHandle) {
      if ("kind" in dir) {
        for await (const handle of dir.values()) {
          if (handle.kind === "file") dataTransfer.items.add(await handle.getFile());
        }
      } else {
        const reader = dir.createReader();
        const entries = await new Promise<FileSystemEntry[]>((resolve, reject) => {
          reader.readEntries(resolve, reject);
        });
        for (const entry of entries) {
          if (entry.isFile) {
            dataTransfer.items.add(await fileSystemEntryFile(entry as FileSystemFileEntry));
          }
        }
      }
    }

    for await (const handle of fileHandlePromises) {
      if (handle === null) {
        continue;
      }

      let file: File | undefined;
      if (isDirectory(handle)) {
        await readDirectory(handle);
      } else {
        if ("kind" in handle) {
          file = await (handle as FileSystemFileHandle).getFile();
        } else {
          file = await fileSystemEntryFile(handle as FileSystemFileEntry);
        }
      }
      if (file) {
        dataTransfer.items.add(file);
      }
    }

    fileInput.files = dataTransfer.files;
    updateControls();
  });

  const pbar = byId<HTMLProgressElement>("progress");
  const pbarFile = byId<HTMLSpanElement>("progress-file");

  let outputDirHandle: FileSystemDirectoryHandle | undefined;

  let inProgress = false;
  let cancelRequest = false;

  form.addEventListener("submit", async (e) => {
    e.preventDefault();

    if (inProgress) {
      cancelRequest = true;
      updateControls();
      return;
    }

    inProgress = true;
    updateControls();

    const formData = new FormData(form);
    const template = formData.get("template") as string | null;

    const renamer = template?.trim() ? new TemplateProcessor(template) : undefined;

    const options: Options = {
      renamer,
      includeEmpty: !!formData.get("includeEmpty"),
      includeSidecar: !!formData.get("includeSidecar"),
    };

    const files = fileInput.files;
    if (!files || !files.length) {
      return;
    }
    let writer: Writer;
    if (!supportsDirectoryWrite || formData.get("outputZip")) {
      writer = new ZipWriter();
    } else {
      if (!outputDirHandle) {
        throw new Error("Output directory not available.");
      }
      writer = new DirectoryWriter(outputDirHandle!);
    }

    const cancelToken = new Error("cancel");
    let pendingWork: Promise<void>[] | undefined;

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        pbarFile.textContent = `Processing ${file.name}...`;

        let ttfs: TtfsTree;
        try {
          ttfs = new TtfsTree(new Uint8Array(await file.arrayBuffer()));
        } catch {
          continue;
        }
        addTree(writer, ttfs, file.name, file.lastModified, options);

        if (writer instanceof DirectoryWriter) {
          pendingWork = writer.flush((p) => {
            if (cancelRequest) {
              throw cancelToken;
            } else {
              const progress = Math.round(((i + p) / files.length) * 100);
              if (progress > pbar.value) {
                pbar.value = progress;
              }
            }
          });
          await Promise.all(pendingWork);
        }
      }

      if (writer instanceof ZipWriter) {
        const outputName = files.length === 1 ? files[0].name : "ezkeys-data.zip";
        pbarFile.textContent = `Zipping ${outputName}...`;

        const outputData = await new Promise<Uint8Array>((resolve, reject) => {
          fflate.zip(writer.files, { consume: true }, (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          });
        });

        saveAs(new Blob([outputData], { type: "application/zip" }), outputName);
      }
    } catch (err) {
      if (pendingWork) {
        await Promise.allSettled(pendingWork);
      }
      cancelRequest = false;
      inProgress = false;
      updateControls();

      if (err === cancelToken) {
        pbar.value = 0;
        pbarFile.textContent = "Cancelled";
        return;
      }
      throw err;
    }

    pbar.value = 100;
    pbarFile.textContent = "Done!";
    cancelRequest = false;
    inProgress = false;
    updateControls();
  });

  const templateEl = selInput("input[name=template]", form);
  const submitEl = selInput("input[type=submit]", form);

  handleEvent(byId<HTMLSelectElement>("template-predef"), "change", (e) => {
    templateEl.value = e.target.value;
    const label = e.target.selectedOptions[0].textContent;
    e.target.selectedIndex = 0;
    e.target.selectedOptions[0].textContent = label;
  });

  if (supportsDirectoryWrite) {
    const elOutputDirButton = byId<HTMLButtonElement>("output-dir");
    const elOutputDirOutput = byId<HTMLOutputElement>("output-dir-output");

    let outputZip = false;

    updateControls = () => {
      submitEl.disabled = !(outputZip || outputDirHandle) || !fileInput.files?.length;
      submitEl.value = inProgress ? (cancelRequest ? "Cancelling" : "Cancel") : "Process";

      elOutputDirButton.disabled = inProgress || outputZip;

      if (outputZip) {
        elOutputDirOutput.hidden = true;
      } else {
        elOutputDirOutput.hidden = false;
        elOutputDirOutput.textContent = outputDirHandle?.name || "No output selected";
      }
    };

    handleEvent(elOutputDirButton, "click", async () => {
      try {
        outputDirHandle = await showDirectoryPicker({
          id: "fs-output-dir",
          mode: "readwrite",
          startIn: "downloads",
        });
      } catch {
        // ignore error that is usually raised just because dialog closed
      }
      updateControls();
    });
    handleEvent(byId<HTMLInputElement>("output-zip"), "change", (e) => {
      outputZip = e.target.checked;
      elOutputDirButton.disabled = outputZip;
      updateControls();
    });
  } else {
    updateControls = () => {
      submitEl.disabled = !fileInput.files?.length;
      submitEl.value = inProgress ? (cancelRequest ? "Cancelling" : "Cancel") : "Process";
    };
  }

  handleEvent(fileInput, "change", updateControls);
}

updateControls();
